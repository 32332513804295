import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'astroturf';
import { isArray, isObject } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { SimpleDropdown } from '../SimpleDropdown';
import { SelectMenu } from '../SelectMenu';
import { SelectMenuWrapper } from '../SelectMenuWrapper';
import { BlockButton } from '../../buttons/BlockButton';
import { Checkbox } from '../Checkbox';
import { Ripple } from '../../../service/Ripple';
import { TextEllipsisWithTooltip } from '../../../service/TextEllipsisWithTooltip';
import { usePromiseCallback } from '../../../../service/hooks/usePromiseCallback';
import { AddSection } from './AddSection';
import { SearchSection } from './SearchSection';
import { EditIconSection } from './EditIconSection';
import { EditSection } from './EditSection';

export interface AdvancedSelectProps {
	value?: any;
	placeholder?: string;
	searchable?: boolean;
	disabled?: boolean;
	items?: any[];
	onGetItems?: () => any;
	onSearch?: (query: string) => Promise<any[]>;
	addable?: boolean;
	onAdd?: (value: string, items?: any[]) => Promise<any>;
	onEditItem?: (value: string, item: any) => Promise<any>;
	onSave?: (value: any[], items?: any[]) => void;
	onChange?: (item: any) => void;
	onBlur?: (e: React.FocusEvent<any>) => void;
	getKey?: (item: any) => string | number;
	getLabel?: (item: any) => string;
	addText?: string;
	searchText?: string;
	className?: string;
	disablePortal?: boolean;
	error?: string;
	touched?: boolean;
	multiple?: boolean;
	multiline?: boolean;
	sameWidth?: boolean;
	editable?: boolean;
	grow?: boolean;
	onClear?: () => void;
}

export const AdvancedSelect: FC<AdvancedSelectProps> = ({
	className,
	placeholder,
	value,
	addable,
	onAdd,
	searchable,
	disabled,
	onSearch,
	onChange,
	onSave,
	items,
	onGetItems,
	addText,
	getLabel,
	disablePortal,
	multiple,
	getKey,
	onBlur,
	touched,
	error,
	multiline,
	sameWidth,
	editable,
	onEditItem,
	onClear,
	grow,
}) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [editableId, setEditableId] = useState<number | null>(null);
	const [open, setOpen] = useState(false);
	const [internalItems, setInternalItems] = useState<any[]>([]);
	const [selectedItems, setSelectedItems] = useState<any[]>([]);
	const [searchQuery, setSearchQuery] = useState('');

	const container = useRef<HTMLDivElement>(null);

	const preventOpen = useRef(false);

	const onAddItem = usePromiseCallback(
		(text: string) => {
			if (onAdd) {
				return onAdd(text, internalItems).then((data) => {
					if (isArray(data)) {
						setInternalItems(data);
					} else {
						setInternalItems([data, ...internalItems]);
					}
				});
			}
		},
		[internalItems, setInternalItems, onAdd],
	);

	const onSaveItems = useCallback(() => {
		onSave && onSave(selectedItems, internalItems);
	}, [internalItems, onSave, selectedItems]);

	const onSearchItems = useCallback(
		(query: string) => {
			if (onSearch) {
				if (query.length) {
					onSearch(query.trim()).then((data: any[]) => {
						setInternalItems(data);
					});
				} else if (onGetItems) {
					onGetItems().then((data: any[]) => {
						setInternalItems(data);
					});
				}
			} else {
				setSearchQuery(query);
			}
		},
		[onSearch, onGetItems, setInternalItems],
	);

	const retrieveKey = useCallback(
		(item: any, index: number) => {
			if (isObject(item as any)) {
				return getKey ? getKey(item) : item.id || item.key;
			} else {
				return index;
			}
		},
		[getKey],
	);

	const retrieveLabel = useCallback(
		(item: any) => {
			if (isObject(item as any)) {
				return getLabel ? getLabel(item) : item.name || item.label;
			} else {
				return item;
			}
		},
		[getLabel],
	);

	const dropdownLabel = useMemo(() => {
		if (multiple) {
			if (isArray(value) && value.length) {
				return value.length > 1 ? `${t('common.ui.selected')}: ${value.length}` : retrieveLabel(value[0]);
			}
		} else if (value) {
			return retrieveLabel(value);
		}
	}, [t, multiple, retrieveLabel, value]);

	const onOpen = useCallback(() => {
		preventOpen.current = false;
		setSearchQuery('');
		if (!disabled) {
			if (items) {
				setInternalItems(items);
				setOpen(true);
			} else {
				if (onGetItems && !loading) {
					setLoading(true);
					Promise.resolve(onGetItems())
						.then((data) => {
							if (!preventOpen.current) {
								setInternalItems(data);
								setOpen(true);
							}
						})
						.finally(() => {
							setLoading(false);
						});
				}
			}
		}
	}, [setOpen, onGetItems, items, disabled, loading, setSearchQuery]);

	const onClose = useCallback(() => {
		setLoading(false);
		preventOpen.current = true;
		setOpen(false);
	}, [setOpen, setLoading]);

	const onSelect = useCallback(
		(item: any) => () => {
			if (onChange && !multiple) {
				onChange(item);
				setOpen(false);
			}
		},
		[onChange, multiple],
	);

	const onMultipleSelect = useCallback(
		(item: any, itemIndex: number) => (event: ChangeEvent<HTMLInputElement>) => {
			let items = value;
			if (!event.target.checked) {
				items = items.filter(
					(el: any, elIndex: number) => retrieveKey(el, elIndex) !== retrieveKey(item, itemIndex),
				);
			} else {
				items = items ? [...items, item] : [item];
			}

			if (onChange) {
				onChange(items);
			}
		},
		[onChange, value, retrieveKey],
	);

	const onClearInternal = () => {
		if (onClear) {
			onClear();
			onClose();
		}
	};

	const onDropdownClick = useCallback(() => {
		if (open) {
			onClose();
		} else {
			onOpen();
		}
	}, [onOpen, onClose, open]);

	const filteredItems = useMemo(() => {
		return searchQuery
			? internalItems.filter(
					(item) => retrieveLabel(item).toLowerCase().indexOf(searchQuery.toLowerCase().trim()) + 1,
			  )
			: internalItems;
	}, [searchQuery, internalItems, retrieveLabel]);

	const handleEdit = useCallback(
		(name: string, item: any) => {
			const newName = name.trim();

			if (!newName) {
				if ((value as any).id && (value as any).id === item.id) {
					onClose();
					onChange && onChange({ id: null });
				}
				onClose();
				return Promise.resolve();
			}
			return (
				onEditItem &&
				onEditItem(newName, item).then((res) => {
					setEditableId(null);
					setInternalItems((oldItems) =>
						oldItems.map((element: any) => (element.id === res.id ? res : element)),
					);
					if ((value as any).id && (value as any).id === res.id) {
						onClose();
						onChange && onChange(res);
					}
				})
			);
		},
		[onClose, onChange, onEditItem, value],
	);

	useEffect(() => {
		setEditableId(null);
	}, [open]);

	return (
		<Wrapper grow={grow}>
			<SimpleDropdown
				ref={container}
				className={className}
				value={dropdownLabel}
				disabled={disabled}
				open={open}
				placeholder={placeholder || t('common.ui.selectPlaceholder')}
				onClick={onDropdownClick}
				loading={loading}
				onBlur={onBlur}
				touched={touched}
				error={error}
				multiline={multiline}
				clearable={Boolean(value && onClear)}
				onClear={onClearInternal}
			/>
			<StyledSelectMenu
				onClickOutside={onClose}
				processClickOutside={open || loading}
				anchor={container}
				open={open}
				disablePortal={disablePortal}
				limitHeight
				sameWidth={sameWidth}
			>
				<StyledSelectMenuWrapper>
					{searchable && <SearchSection onSearch={onSearchItems} />}
					{addable && <AddSection onAdd={onAddItem} addText={addText} />}
					<Items>
						{filteredItems.map((item, index) => {
							const key = retrieveKey(item, index);

							return editable && editableId === index && onEditItem ? (
								<EditSection onEdit={handleEdit as any} value={retrieveLabel(item)} item={item} />
							) : (
								<ItemWrapper>
									<Ripple key={key} onClick={onSelect(item)}>
										<Item htmlFor={String(key)} withCheckbox={multiple}>
											{multiple && (
												<CheckboxWrapper>
													<Checkbox
														id={String(key)}
														onChange={onMultipleSelect(item, index)}
														checked={
															isArray(value)
																? value.some(
																		(el: any) => retrieveKey(el, index) === key,
																  )
																: false
														}
													/>
												</CheckboxWrapper>
											)}
											{multiline ? (
												<MultilineText>{retrieveLabel(item)}</MultilineText>
											) : (
												<InlineText>{retrieveLabel(item)}</InlineText>
											)}
											{editable && <EditIconSection id={index} onClick={setEditableId} />}
										</Item>
									</Ripple>
								</ItemWrapper>
							);
						})}
					</Items>

					{onSave && (
						<SaveButton>
							<BlockButton color={'blue'} onClick={onSaveItems}>
								{t('common.actions.save')}
							</BlockButton>
						</SaveButton>
					)}
				</StyledSelectMenuWrapper>
			</StyledSelectMenu>
		</Wrapper>
	);
};

const StyledSelectMenu = styled(SelectMenu)`
	max-width: 576px;
	min-width: 192px;
`;

const StyledSelectMenuWrapper = styled(SelectMenuWrapper)`
	height: 100%;
	display: flex;
	flex-direction: column;
`;

const Wrapper = styled.div<{ grow?: boolean }>`
	@import '../../../../styles/constants.scss';
	position: relative;
	&.grow {
		min-width: 0;
		width: 100%;
	}
`;

const CheckboxWrapper = styled.div`
	margin-right: 7px;
`;

const ItemWrapper = styled.div`
	height: 35px;
`;

const Item = styled.label<{ withCheckbox?: boolean }>`
	@import '../../../../styles/constants.scss';
	text-align: left;
	font-size: 12px;
	display: flex;
	align-items: center;
	width: 100%;
	padding: 10px 11px 10px;
	cursor: pointer;
	&:hover {
		background-color: rgba($color-gray, 0.5);
	}
	&.withCheckbox {
		padding: 2px 6px 2px;
	}
`;

const MultilineText = styled.span``;

const InlineText = styled(TextEllipsisWithTooltip)`
	padding-bottom: 1px;
	white-space: nowrap;
`;

const Items = styled.div`
	@import '../../../../styles/mixins.scss';
	@include thin-scrollbar(#d8d8d8);
	max-height: 320px;
	overflow: auto;
	width: 100%;
	height: 100%;
	margin-top: 4px;
	margin-bottom: 16px;
`;
const SaveButton = styled.div`
	margin: 6px 9px 6px 12px;
`;
