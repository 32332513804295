import React, { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'astroturf';
import { useTranslation } from 'react-i18next';
import { useOnEnter } from '../../../../service/hooks/useOnEnter';
import { Ripple } from '../../../service/Ripple';
import { TextButton } from '../../buttons/TextButton';

interface IProps {
	onAdd: (text: string) => Promise<any>;
	addText?: string;
	saveText?: string;
	withoutButtonAdd?: boolean;
}

export const AddSection: FC<IProps> = ({ onAdd, addText, saveText, withoutButtonAdd: withoutAddButton }) => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const [adding, setAdding] = useState(false);
	const [value, setValue] = useState('');

	const input = useRef<HTMLInputElement>(null);

	const onChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			setValue(event.target.value);
		},
		[setValue],
	);

	const onOpen = useCallback(() => {
		setOpen(true);
	}, [setOpen]);

	const onSubmit = useCallback(() => {
		const trimmedValue = value.trim();
		if (trimmedValue) {
			onAdd(trimmedValue)
				.then(() => {
					setOpen(false);
				})
				.finally(() => {
					setAdding(false);
					setValue('');
				});
		}
	}, [onAdd, value, setOpen, setAdding]);

	const onEnter = useOnEnter(() => {
		onSubmit();
	}, [onSubmit]);

	useEffect(() => {
		if (open && input.current) {
			input.current.focus();
		}
	}, [open]);

	return (
		<Wrapper>
			{open || withoutAddButton ? (
				<AddInputWrapper>
					<AddInput ref={input} value={value} onChange={onChange} onKeyPress={onEnter} />
					<SaveButton disabled={!value || !value.trim() || adding} onClick={onSubmit}>
						{saveText || t('common.actions.save')}
					</SaveButton>
				</AddInputWrapper>
			) : (
				<Ripple onClick={onOpen}>
					<AddButton>{addText || t('common.actions.addSomething')}</AddButton>
				</Ripple>
			)}
		</Wrapper>
	);
};

const Wrapper = styled.div`
	@import '../../../../styles/constants.scss';
	color: $color-blue;
	font-size: 12px;
`;

const AddInputWrapper = styled.div`
	height: 24px;
	border: solid 1px #f5f5f5;
	display: flex;
	align-items: center;
	margin: 6px 9px 5px 12px;
`;

const AddInput = styled.input`
	border: none;
	outline: 0;
	min-width: 0;
	text-overflow: ellipsis;
	padding-left: 8px;
	font-size: 12px;
	width: 100%;
`;

const AddButton = styled.div`
	@import '../../../../styles/constants.scss';
	padding: 7px 12px;
	font-size: 12px;
	&:hover {
		background-color: rgba($color-gray, 0.5);
	}
`;

const SaveButton = styled(TextButton)`
	font-weight: normal !important;
	width: 85px;
`;
